export default function Checkout() {
    checkoutFunctions();
}

function checkoutFunctions() {

    $("#order-summary-toggle").click(function () {
        $("#order-summary").slideToggle(800);
        $("svg", this).toggleClass('rotate-svg');
    });

    $('.checkbox-wrapper').on('click', function () {
        var checkbox = $(this).children('input[type="checkbox"]');
        checkbox.prop('checked', !checkbox.prop('checked'));
    });

    $("#label-billing-chbx").click(function () {

        $("#shipping-info").slideToggle("slow", "swing", function () {
           
            if ($('#billing-chbx').is(':checked')) {

                $("[id^='shippingAddress-']").each(function () {
                    var data = $(this).attr("id");
                    var tmpID = data.split('shippingAddress-');
                    $(this).val($("#billingAddress-" + tmpID[1]).val());
                });
            }
            else {
                $("[id^='shippingAddress-']").each(function () {
                    $(this).val("");
                });
            }
        });

    });

    
    if ($("#lbl-acceptTerms").length > 0) {

        $("#continue").addClass('btn-disabled');
        $("#continue").attr('disabled', true);

        document.getElementById('lbl-acceptTerms').addEventListener('click', function () {
            $("#continue").toggleClass('btn-disabled');
            $('#continue').attr('disabled', (_, attr) => attr == "disabled" ? null :  "disabled");
        }); 
    }

    if ($(".shipping-method-radio.payment-type").length > 0) {

        $("#continue").addClass('btn-disabled');
        $("#continue").attr('disabled', true);

        document.querySelectorAll('.payment-radio').forEach(element => element.addEventListener('click', event => {
            $("#continue").removeClass('btn-disabled');
            $('#continue').prop('disabled', false);
        }));

    }
}

