import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Animations() {
    animations()    
}

//
// Define your functions here
//
function animations() {

 gsap.registerPlugin(ScrollTrigger);

//apply class is-active to all sections when they are in viewport
const sections = document.querySelectorAll('section');

sections.forEach((section) => {
    gsap.to(section, {       
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: () => `+=${section.clientHeight + 30}`,
          toggleActions: 'play reverse none reverse',
          toggleClass: {targets: section, className: "is-active"}
      }
    });

});


// fade In section
const sectionsFadeUp = document.querySelectorAll('.fadeUp');

sectionsFadeUp.forEach((section) => {
    gsap.to(section, {
        autoAlpha: 1,
        scrollTrigger: {
        trigger: section,
        start: 'top bottom-=10%',
        toggleActions: 'play none none reverse'
      }
    });
    
});


}