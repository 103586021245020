import 'slick-carousel'

export default function CollectionCarousel () {
  init()
}

function init() {

    var $numberedPager = $('.custom_paging');
    var $slickElement = $('#image-gallery');

    $slickElement.on('init reInit afterChange', function (event, slick, currentSlide) {
       
        var i = (currentSlide ? currentSlide : 0) + 1;
        var getSliderNo = i;
        var getTotalSlides = slick.slideCount;
        var currentSlideNo = getSliderNo < 10 ? "0" + getSliderNo : currentSlideNo = getSliderNo;
        var totalSlidesNo = getTotalSlides < 10 ? "0" + getTotalSlides : getTotalSlides;

        if(totalSlidesNo > 1){
            $numberedPager.html('<span class="slider-pag-first">' + currentSlideNo + '</span>' + '<span class="pad-divider"></span>' + '<span class="slider-pag-second">' + totalSlidesNo +'</span>');
        }

    });

    $('#image-gallery').slick({
        prevArrow: '<span class="slick-prev slick-arrow"><svg class="[ icon icon-arrow-left ]"><use xlink:href="#icon-arrow-left"></use></svg></span>',
        nextArrow: '<span class="slick-next slick-arrow"><svg class="[ icon icon-arrow-right ]"><use xlink:href="#icon-arrow-right"></use></svg></span>'
    });
   

    var sliderId = document.querySelectorAll('.collection-carousel__container--slider');

    for (var i = 0; i < sliderId.length; ++i) {

      $(sliderId[i]).not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        prevArrow: '<span class="slick-prev slick-arrow"><svg class="[ icon icon-arrow-left ]"><use xlink:href="#icon-arrow-left"></use></svg></span>',
        nextArrow: '<span class="slick-next slick-arrow"><svg class="[ icon icon-arrow-right ]"><use xlink:href="#icon-arrow-right"></use></svg></span>',
        responsive: [
          {
            breakpoint: 920,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
              arrows : false,
            }
          }
        ]
      });
    }

}
