export function CartViewed(products) {

    var getTotal = document.querySelector('.order-subtotal');
    var subTotal = getTotal.dataset.value;
    var storeCurrency = "";

    if (products.length > 0) {
        var productList = [];

        products.forEach(function callback(product, index) {

            storeCurrency = product.dataset.currency;

            var item = {
                item_id: product.dataset.sku,
                item_name: product.dataset.name,
                affiliation: "Riobel",
                currency: product.dataset.currency,
                index: index,
                item_brand: "Riobel | " + product.dataset.category,
                item_category: product.dataset.category,
                price: parseFloat(product.dataset.price),
                quantity: parseInt(product.dataset.quantity)
            };

            productList.push(item);
        });

        dataLayer = dataLayer.filter(x => !x.ecommerce);  // Clear the previous ecommerce object.
        dataLayer.push({
            event: "view_cart",
            currency: storeCurrency,
            value: parseFloat(subTotal),
            ecommerce: {
                items: productList
            }
        });
    }
}

export function BeginCheckout(products) {

    var totalAmount = document.querySelector('.checkout-total-item');
    var totalValue = parseFloat(totalAmount.dataset.value);
    var storeCurrency = "";
    var getVariant = document.querySelector(".checkout-product-name").innerHTML.split(':').map(item => item.trim());
    var variant = getVariant[1];

    if (products.length > 0) {
        var productList = [];

        products.forEach(function callback(product, index) {

            storeCurrency = product.dataset.currency;

            productList.push({
                item_id: product.dataset.sku,
                item_name: product.dataset.name,
                affiliation: "Riobel",
                currency: product.dataset.currency,
                index: index,
                item_brand: "Riobel | " + product.dataset.category,
                item_category: product.dataset.category,
                item_variant: variant,
                price: parseFloat(product.dataset.price),
                quantity: parseInt(product.dataset.quantity)
            });
        });

        dataLayer = dataLayer.filter(x => !x.ecommerce);  // Clear the previous ecommerce object.
        dataLayer.push({
            event: "begin_checkout",
            currency: storeCurrency,
            value: totalValue,
            ecommerce: {
                items: productList
            }
        });
    }
}

export function AddShippingInfo(products, shippingMethod) {

    var totalAmount = document.querySelector('.checkout-total-item');
    var totalValue = parseFloat(totalAmount.dataset.value);
    var storeCurrency = "";
    var getVariant = document.querySelector(".checkout-product-name").innerHTML.split(':').map(item => item.trim());
    var variant = getVariant[1];

    if (products.length > 0) {
        var productList = [];

        products.forEach(function callback(product, index) {

            storeCurrency = product.dataset.currency;

            productList.push({
                item_id: product.dataset.sku,
                item_name: product.dataset.name,
                affiliation: "Riobel",
                currency: product.dataset.currency,
                index: index,
                item_brand: "Riobel | " + product.dataset.category,
                item_category: product.dataset.category,
                item_variant: variant,
                price: parseFloat(product.dataset.price),
                quantity: parseInt(product.dataset.quantity)
            });
        });

        dataLayer = dataLayer.filter(x => !x.ecommerce);
        dataLayer.push({
            event: "add_shipping_info",
            currency: storeCurrency,
            value: totalValue,
            shipping_tier: shippingMethod,
            ecommerce: {
                items: productList
            }
        });
    }
}

export function AddPaymentInfo(products, paymentType) {
   
    var totalAmount = document.querySelector('.checkout-total-item');
    var totalValue = parseFloat(totalAmount.dataset.value);
    var storeCurrency = "";
    var getVariant = document.querySelector(".checkout-product-name").innerHTML.split(':').map(item => item.trim());
    var variant = getVariant[1];

    if (products.length > 0) {
        var productList = [];

        products.forEach(function callback(product, index) {

            storeCurrency = product.dataset.currency;

            productList.push({
                item_id: product.dataset.sku,
                item_name: product.dataset.name,
                affiliation: "Riobel",
                currency: product.dataset.currency,
                index: index,
                item_brand: "Riobel | " + product.dataset.category,
                item_category: product.dataset.category,
                item_variant: variant,
                price: parseFloat(product.dataset.price),
                quantity: parseInt(product.dataset.quantity)
            });
        });

        dataLayer = dataLayer.filter(x => !x.ecommerce);
        dataLayer.push({
            event: "add_payment_info",
            currency: storeCurrency,
            value: totalValue,
            payment_type: paymentType,
            ecommerce: {
                items: productList
            }
        });
    }
}
