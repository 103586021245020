export default function Pagination() {
    init()
}

function init() {

    $('.page-size-filter').appendTo('.listing-header');

    $(document).on("change", "#listing-size", function () {
        updateAndTriggerFilter(true, $(this).val());
    });

    function updateAndTriggerFilter(isPageSize, newValue) {

        var offset = $(".product-list").offset().top - 100;

        $("html, body").animate({ scrollTop: offset }, function () {
           
            document.getElementById(isPageSize ? "ps" : "p").value = newValue;
            document.querySelector(".filter-form-inline .buttons button[type='submit']").click();
        });
    }
}