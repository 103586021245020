import Pagination from '../modules/pagination'
import * as DataLayerProductListings from '../modules/dataLayer/dataLayerProductListings';


export default function ProductCategory() {
    Pagination()
    DataLayerProductListings.GetProducts()
}


document.querySelectorAll(".listing-item a").forEach(element => {
    element.addEventListener("click", function (e) {
        DataLayerProductListings.OnProductClick(element.closest(".listing-item"));
    });
});

