import * as DataLayerProduct from '../modules/dataLayer/dataLayerProductPage';

export default function ProductDetails() {
    productFinishSelector()
}

if (document.getElementsByClassName('product-detail__info').length > 0) {
    DataLayerProduct.ViewProductDetail({
        item_id: document.querySelector(".product-details").dataset.sku,
        item_name: document.querySelector(".product-detail__info h2").innerText,
        affiliation: "Riobel",
        currency: document.querySelector(".product-details").dataset.currency,
        index: 0,
        item_brand: "Riobel | " + document.querySelector(".product-details").dataset.category,
        item_category: document.querySelector(".product-details").dataset.category,
        price: parseFloat(document.querySelector(".product-details").dataset.price),
        quantity: 1
    });
}


function productFinishSelector() {
    var finishImages = document.querySelectorAll('.product-detail__image--img');
    var finishName = document.querySelectorAll('#product-finish');
    var sku = document.querySelectorAll('.product-sku');
    var select = document.querySelectorAll('.finish-select__item');

    for (var p = 0; p < select.length; p++) {
        select[p].addEventListener("click", function () {
            if (finishImages) {
                for (var sl = 0; sl < select.length; sl++) {
                    select[sl].classList.remove('border');
                }

                for (var i = 0; i < finishImages.length; i++) {
                    var finish = finishImages[i].getAttribute('data-finish');
                    var selectVar = this.textContent.trim().replace(/&nbsp;/g, '');
                    var selectSku = this.getAttribute('data-sku');
                    finishImages[i].style.display = 'none';
                    this.classList.add('border');
                    if (selectVar === finish) {

                        for (var s = 0; s < sku.length; s++) {
                            sku[s].innerHTML = selectSku;
                        }

                        for (var j = 0; j < finishName.length; j++) {
                            finishName[j].innerHTML = ': ' + finish;
                            finishName[j].style.display = 'inline-block';
                        }
                        
                        finishImages[i].style.display = 'block';
                    }
                }
            }
        });
    }
}


