import 'magnific-popup'

export default function Lightbox() {
    init()
}

function init() {
    $('.lightbox-youtube').each(function () {
        $(this).magnificPopup({
            type: 'iframe',
            closeMarkup: '<button title="%title%" type="button" class="mfp-close close-btn">Close <svg class="[ icon icon-close white-close mfp-close ]"><use xlink:href="#sprite-icon-close"></use></svg></button>',
            iframe: {
                markup: '<div class="mfp-iframe-scaler">' +
                        '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
                        '</div>',
                patterns: {
                    youtube: {
                        index: 'youtube.com/',
                        id: 'v=',
                        src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1'
                    }
                }
            }
        });
    });
}