import * as DataLayerProduct from '../modules/dataLayer/dataLayerProductPage';
import * as DataLayerProductListings from '../modules/dataLayer/dataLayerProductListings';

export default function Form() {
    formFunctions()
    addToBasket();
    finishValidation();
    updateQuantity();
}

function formFunctions() {    
    document.querySelectorAll('.reset-form').forEach(item => {
    
      item.addEventListener('click', function() {
          var inputs = this.closest('form').elements;
          
          for (let index = 0; index < inputs.length; index++) {
            const el = inputs[index];
            
            switch(el.type) {
              case "checkbox":
                el.removeAttribute("checked");
                break;
          
              default:
                el.value = "";
                break;
            }
          }
      });
      
    });

    function formSubmitHideHeader() {
      if($('.umbraco-forms-submitmessage').length) {
          $('.umbraco-forms-submitmessage').parents(".form-pod").find(".form-pod-header").hide();
      }
    }

    formSubmitHideHeader()
}

function finishValidation() {
    $(".finish-select li").on("click", function () {
        $(".finish-error").hide();
        $('.add-to-basket').prop('disabled', false);
        $('.add-to-basket').removeClass("btn-disabled");

    });
}


function updateQuantity() {
    $(".update-basket-form").on("submit", function (e) {
        e.preventDefault();
        var formData = $(this)[0];
        $('.quantity input[type=submit]').attr("disabled", true);

        $.ajax({
            url: $(this).prop('action'),
            type: "POST",
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data',
            data: new FormData(formData),
            dataType: "json",
            success: function (data) {
                if (data.response == "success") {

                    var $basketTrigger = $(".header-icons .shopping-basket");
                    $basketTrigger.find(".items").html(data.orderQty);
                    $basketTrigger.data("qty", data.orderQty);

                    $('.basket-vat.cost').text(data.orderPrice.Tax);
                    $('.order-subtotal').text(data.orderPrice.WithTax);
                    $("header .basket-wrapper").replaceWith(data.headerBasket);

                    var itemCount = $basketTrigger.find(".items").html();

                    if (itemCount == "0") {
                        $basketTrigger.find(".items").hide();
                    }
                    else {
                        $basketTrigger.find(".items").show();
                    }

                    $('.quantity input[type=submit]').attr("disabled", false);
                }
            },
            error: function (xhr) {
                console.log(xhr);
            }
        });
    });
}

function addToBasket() {

    $('.finish-select ul li').on('click', function () {
        var getProductRef = $(this).attr('data-name');
        var skuRef = $(this).attr('data-sku');
        var getPrice = $(this).attr('data-price');
        $('#ProductReference').val(getProductRef);
        $('#SKU').val(skuRef);
        $('.product-price__from').addClass("hide");
        $('.product-price__price').text(getPrice);
    });

    $(".customise-buy-form").on("submit", function (e) {
        e.preventDefault();

        var _finish = document.querySelector(".border") != null ? document.querySelector(".border").innerText : null;
       
        if (_finish == null) {

            $(".finish-error").show();
            $('.add-to-basket').prop('disabled', true);
            $('.add-to-basket').addClass("btn-disabled");
            return;
        }
        else {
            $(".finish-error").hide();
            $('.add-to-basket').prop('disabled', false);
            $('.add-to-basket').removeClass("btn-disabled");
        }

        var formData = $(this)[0];
        var formatUnitPrice = document.querySelector(".product-price__price").innerHTML.replace(/[^0-9.]/g, '');

        var product = {
            item_id: document.querySelector(".product-details").dataset.sku,
            item_name: document.querySelector(".product-detail__info h2").innerText,
            affiliation: "Riobel",
            currency: document.querySelector(".product-details").dataset.currency,
            index: 0,
            item_brand: "Riobel | " + document.querySelector(".product-details").dataset.category,
            item_category: document.querySelector(".product-details").dataset.category,
            item_variant: document.querySelector(".border").innerText,
            price: parseFloat(formatUnitPrice),
            quantity: document.getElementById('quantity').value
        }
       
        var formValues = [];

        product = DataLayerProductListings.MergeObjects(product, formValues);

        $.ajax({
            url: $(this).prop('action'),
            type: "POST",
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data',
            data: new FormData(formData),
            dataType: "json",
            success: function (data) {
                if (data.response == "success") {
                    
                    DataLayerProduct.AddProductToBasket(product);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    var $basketTrigger = $(".header-icons .shopping-basket");

                    //Close popup
                    $.magnificPopup.close();

                    //Update and display header basket
                    $basketTrigger.find(".items").html(data.orderQty);
                    $basketTrigger.data("qty", data.orderQty);

                    var itemCount = $basketTrigger.find(".items").html();

                    if (itemCount == "0") {
                        $basketTrigger.find(".items").hide();
                    }
                    else {
                        $basketTrigger.find(".items").show();
                    }


                    $("header .basket-wrapper").replaceWith(data.headerBasket);
                    

                    setTimeout(function () {
                        $basketTrigger.click();

                        $('.basket-items').animate({
                            scrollTop: $('.basket-items ul li:last-child').position().top
                        }, 'slow');
                        
                        $('.basket-items ul li:last-child .basket-item-msg').show().delay(3000).fadeOut();
                        

                        setTimeout(function () {
                            $basketTrigger.click();
                        }, 5000);
                    }, 500);
                }
                else {
                    $(".add-to-basket-error span").empty();
                    $(".add-to-basket-error ").show();
                    $(".add-to-basket-error ").append("<span>" +data.errorMessage + "</span>");
                }
            },
            error: function (xhr) {
                console.log(xhr);
            }
        });
    });
}

