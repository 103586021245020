

export default function FAQ() {
    accordion();
    accordionMultiActive();
}

//
// Define your functions here
//
function accordion() {

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {

      var faqs = this.closest(".faqSection").getElementsByClassName("faqs");
      
      for (var faq = 0; faq < faqs.length; faq++) {
        if(faqs[faq].querySelector(".accordion") != this) {
          faqs[faq].querySelector(".accordion").classList.remove("active");
          faqs[faq].querySelector(".panel").classList.remove("active");
        }
      }

      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      panel.classList.toggle("active");
    
    });
  }


}

function accordionMultiActive() {

    var acc = document.getElementsByClassName("accordion-multi");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            panel.classList.toggle("active");
        });
    }
}