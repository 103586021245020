export function AddProductToBasket(product) {
    
    var totalAmount = product.price * product.quantity;

    dataLayer = dataLayer.filter(x => !x.ecommerce);  // Clear the previous ecommerce object.
    dataLayer.push({
        event: "add_to_cart",
        currency: product.currency,
        value: totalAmount,
        ecommerce: {
            items: [product]
        }
    });
}


export function ViewProductDetail(product) {
   
    dataLayer = dataLayer.filter(x => !x.ecommerce);  // Clear the previous ecommerce object.
    dataLayer.push({
        event: "view_item",
        currency: product.currency,
        value: parseFloat(product.price),
        ecommerce: {
            items: [product]
        }
    });
}
