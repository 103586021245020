import Animations from '../modules/animations';
import Filter from '../modules/filter';

export default function FilterForms() {
    ProductListingChange()
}

$("#pac-input").keyup(function () {
    if (document.getElementById("pac-input").value === "") {
        document.getElementById('search-map').disabled = true;
        $('.validation-msg').show();
    }
});

function ProductListingChange() {
    $(".filter-form").on("submit", function (e) {
        e.preventDefault()
    
        var formData = new FormData($(this)[0]);
    
        $.ajax({
            url: $(this).prop('action'),
            type: "POST",
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data',
            data: formData,
            success: function (response) {
                $(".listing-container").replaceWith(response);
                document.getElementById("p").value = 1;
                Animations();
                Filter();
                
                var filterPanel = document.getElementById("filter");
                filterPanel.classList.remove("show"); 
                filterPanel.classList.add("hide");   
            }
        });
    });

    $(document).on("click", ".pag-number", function (evt) {
        evt.preventDefault();

        var checkParam = $(this).prop("href").split('=')[0];
        var pageNum = $(this).prop("href").split('=')[1];

        var param = checkParam.split('?')[1];

        if (!$.isNumeric(pageNum)) {

            pageNum = 1;
        }

        updateAndTriggerFilter(false, pageNum, param);
    });

    function updateAndTriggerFilter(isPageSize, newValue, queryParam) {

        var offset = $(".results-container").offset().top - 100;

        if (queryParam !== "p") {
            window.location.href = window.location.href.split("?")[0] + "?p=1";
        }

        $("html, body").animate({ scrollTop: offset }, function () {
            document.getElementById(isPageSize ? "ps" : "p").value = newValue;
            document.querySelector(".filter-form .buttons .submit").click();
        });
    }
}
