import * as DataLayerBasket from '../modules/dataLayer/dataLayerBasket';
import * as DataLayerCheckout from '../modules/dataLayer/dataLayerCheckout';

export default function Basket() {
    basketFunctions();
}

document.querySelectorAll(".basket-remove").forEach(element => {
    element.addEventListener("click", function (e) {

        var product = {
            item_name: element.dataset.name,
            item_id: element.dataset.sku,
            item_price: element.dataset.price,
            quantity: parseInt(element.dataset.quantity)
        };

        DataLayerBasket.RemoveFromBasket(product);
    });
});

if (document.getElementsByClassName("basket-container").length > 0) {
    DataLayerCheckout.CartViewed(document.querySelectorAll(".basket-container .basket-items"));
}

if (document.getElementsByClassName("checkout-step-one").length > 0) {
    DataLayerCheckout.BeginCheckout(document.querySelectorAll(".order-summary-item-list .checkout-item"));
}

if (document.getElementsByClassName("shipping-method").length > 0) {

    DataLayerCheckout.AddShippingInfo(document.querySelectorAll(".order-summary-item-list .checkout-item"), $('.shipping-method-radio.shipping-method input:radio:checked').attr("data-method"));

    $('.shipping-method-radio.shipping-method input[type="radio"]').on('change', function (e) {
        DataLayerCheckout.AddShippingInfo(document.querySelectorAll(".order-summary-item-list .checkout-item"), this.getAttribute("data-method"));
    });
}

if (document.getElementsByClassName("payment-type").length > 0) {

    $('.shipping-method-radio.payment-type input[type="radio"]').on('change', function (e) {
        var paymentMethod = this.getAttribute("data-type");
        DataLayerCheckout.AddPaymentInfo(document.querySelectorAll(".order-summary-item-list .checkout-item"), paymentMethod);
    });
}

document.addEventListener('click', function (event) {

    if (document.getElementsByClassName('overlay-open').length > 0) {
        var specifiedElement = document.getElementById('header-basket');
        var isClickInside = specifiedElement.contains(event.target);

        if (!isClickInside && !event.target.matches('.header-icons *')) {
            var closeBasket = document.getElementsByClassName('basket-close')[0];
            closeBasket.click();
        }
    }
});

function basketFunctions() {

    window.addEventListener('DOMContentLoaded', () => {

        var $basketItems = $(".header-icons .shopping-basket");
        var itemCount = $basketItems.find(".items").html();

        if (itemCount == "0") {
            $basketItems.find(".items").hide();
        }
        else {
            $basketItems.find(".items").show();
		}

    });
}




