import Cookies from 'js-cookie'
import 'parsleyjs'

export default function DownloadFiles() {
  init()
}

function init() {

  const downloadForm = document.getElementById("download-form");
  const successMessage = document.getElementById("success-message");
  const errorMessage = document.getElementById("error-message");
  const downloadFormPopUp = document.querySelector('.download-form');
  const downloadCloseButton = document.querySelector('.download-form__inner--close');

  if (!Cookies.get("download-form")) {
    $(downloadForm).parsley();
  }

  if(downloadFormPopUp) {
      downloadCloseButton.addEventListener('click', function () {
        downloadFormPopUp.classList.remove('active');
      });
  }

  $('.download-item').on("click", function (e) {

    e.preventDefault();
    e.stopPropagation();

    if (!Cookies.get("download-form")) {
      downloadFormPopUp.classList.add('active');
      var fileIdInput = document.getElementById("FileIds");
      var fileNameSpan = document.getElementById("FileName");
      fileIdInput.value = $(this).data("file-id");
      fileNameSpan.innerHTML = ($(this).data("file-name") != "" && $(this).data("file-name") != undefined) ? $(this).data("file-name") : "All downloads";

      var nodeIdInput = document.getElementById("FileDownloadNodeId");
      nodeIdInput.value = $(this).data("node-id");
    } else {
      if (typeof(downloadForm) != 'undefined' && downloadForm != null)
      {
        downloadForm.style.display = "none"
        successMessage.style.display = "block";
      }
      downloadFile($(this).data("file-id").toString().split(','), $(this).data("node-id"));
    }
  });

  $(downloadForm).on("submit", function (e) {
    e.preventDefault();
    if (!Cookies.get("download-form")) {
      submitDownloadForm($(this));
    }
  });

  function submitDownloadForm(form) {

    var url = location.protocol + '//' + location.host + form.attr("action");
    var formData = form[0];

    $.ajax({
      type: 'POST',
      processData: false,
      contentType: false,
      data: new FormData(formData),
      async: false,
      url: url,
      success: function (data) {
        submitDownloadFormSuccess(data);
      },
      error: function (jqkhr) {
        console.log("ERROR " + jqkhr.responseText);
      }
    });
  }

  function submitDownloadFormSuccess(data) {

    if (data != undefined) {
      if (data.mediaIds != undefined) {
        downloadForm.style.display = "none"
        successMessage.style.display = "block";

        if (!Cookies.get("download-form")) {
          Cookies.set("download-form", true, {
            expires: 365
          });
        }

        // $(".download-item").addClass("disable-popup");

        downloadFile(data.mediaIds, data.nodeId);
      } else if (data.errorMessage != undefined) {
        console.log(data.exceptionMessage);
        errorMessage.innerHTML = data.errorMessage;
      }
    }
  }

  function downloadFile(dataFileIds, nodeId) {

    if (dataFileIds.length > 1) {
      createAndDownloadZip(dataFileIds, nodeId);
    } else {
      if (dataFileIds[0] == "-1") {
        createAndDownloadZip(dataFileIds, nodeId);
      } else {
        window.location.href = "/umbraco/Surface/FileDownload/DownloadFile?mediaId=" + dataFileIds[0];
      }
    }
  }

  function createAndDownloadZip(dataFileIds, nodeId) {
    $.ajax({
        type: "POST",
        data: JSON.stringify({
          mediaIds: dataFileIds,
          nodeId
        }),
        url: "/umbraco/Surface/FileDownload/GetZip",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        error: function (jqkhr) {
          console.log("ERROR " + jqkhr.responseText);
        }
      })
      .done(function (data) {
        if (data) {
          if (data.allData) {
            var $downloadTrigger = $("<a>Download</a>").attr({
              "href": data.filePath,
              "id": "download-trigger",
              "download": data.fileName,
              "style": "display:none"
            });
            $downloadTrigger.appendTo(".download-all");

            $("#download-trigger")[0].click();
            $("#download-trigger").remove();
          } else if (data.fileId != undefined) {
            window.location.href = "/umbraco/Surface/FileDownload/DownloadZip?fileId=" + data.fileId + "&allData=" + data.allData;
          }
        }
      });
  }
}