export function RemoveFromBasket(product) {

    var totalAmount = product.price * product.quantity;

    dataLayer = dataLayer.filter(x => !x.ecommerce);  // Clear the previous ecommerce object.
    dataLayer.push({
        event: "remove_from_cart",
        currency: product.currency,
        value: totalAmount,
        ecommerce: {
            items: [product]
        }
    });
}

export function CheckoutClicked(products) {


    var productList = [];

    products.forEach(product => {

        productList.push({
            item_name: product.dataset.name,
            item_id: product.dataset.sku,
            item_price: product.dataset.price,
            quantity: parseInt(product.dataset.quantity)
        });
    });

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            items: productList
        }
    });
}