export default function Filter() {
    filter()    
}

//
// Define your functions here
//
function filter() {

    if(document.body.contains(document.getElementById("filter-btn"))){



      var filterBtn = document.getElementById("filter-btn");
      var filterPanel = document.getElementById("filter");

      filterBtn.addEventListener("click", function() {

        filterPanel.classList.add("show"); 
        filterPanel.classList.remove("hide");    
        
      });
     
      var filterClose = document.getElementById("closeFilter");

      filterClose.addEventListener("click", function() {

        filterPanel.classList.remove("show"); 
        filterPanel.classList.add("hide");   
        
      });

   }

}